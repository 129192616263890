import 'classlist.js';

const loaderHTML = `<div class="loader">
	<div class="loader-animation sk-three-bounce">
		<div class="sk-child sk-bounce1"></div>
		<div class="sk-child sk-bounce2"></div>
		<div class="sk-child sk-bounce3"></div>
	</div>
</div>`;

function appendTo( target ) {
	const loader = document.createRange().createContextualFragment( loaderHTML );
	return target.appendChild( loader );
}

function insertInfiniteScrollStatus( target ) {
	appendTo( target );
	document.querySelector( '.loader-animation' ).classList.add( 'infinite-scroll-request' );
}

export default {
	appendTo,
	insertInfiniteScrollStatus,
};
