const widths = {
	tabletPortrait: 768,
	tabletLandscape: 992,
	desktop: 1210,
};

function get() {
	const width = window.innerWidth || document.documentElement.clientWidth;
	return ( width < widths.tabletLandscape ) ? 'mobile' : 'desktop';
}

export default {
	widths,
	get,
};
