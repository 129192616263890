import 'classlist.js';
import 'element-closest';
import '../lib/matches';

const main = document.getElementById( 'main' );

function updateLocationOrOpenNewTab( e, link ) {
	if ( link ) {
		e.preventDefault();
		const isExternal = link.host !== window.location.host;

		if ( isExternal || e.ctrlKey || e.metaKey ) {
			const newWindow = window.open();
			newWindow.opener = null;
			newWindow.location = link.href;
		} else {
			window.location = link.href;
		}
	}
}

function makePostPreviewClickable( e ) {
	const excludeSelectors = [
		'.postMeta-byline a',
	];

	const handleClick = !excludeSelectors.some( value => e.target.matches( value ) );

	if ( handleClick ) {
		const blockLink = e.target.closest( '.blockLink' );
		if ( blockLink ) {
			updateLocationOrOpenNewTab( e, blockLink.querySelector( '.blockLink-url' ) );
		}
	}
}

function init() {
	main.addEventListener( 'click', ( e ) => {
		makePostPreviewClickable( e );
	} );
}
export default {
	init,
};
