import 'classlist.js';
import debounce from 'lodash.debounce';

const collections = document.querySelectorAll( '.js-cards' );
const activatedClass = 'has-height';

function getTallest( collection ) {
	const cards = collection;
	let cardHeight = 0;

	for ( let i = 0; i < cards.length; i += 1 ) {
		const imageHeight = cards[i].children[0].offsetHeight;
		const titleHeight = cards[i].children[1].offsetHeight;
		const height = imageHeight + titleHeight;

		if ( height > cardHeight ) {
			cardHeight = height;
		}
	}

	return cardHeight;
}

function setHeights( collection ) {
	const cards = collection;
	const cardHeight = getTallest( cards );

	for ( let i = 0; i < cards.length; i += 1 ) {
		cards[i].style.height = `${cardHeight}px`;
		cards[i].classList.add( activatedClass );
	}
}

function initCollections() {
	for ( let i = 0; i < collections.length; i += 1 ) {
		const cards = collections[i].querySelectorAll( '.card' );
		setHeights( cards );
	}
}

function initEvents() {
	window.addEventListener( 'resize', debounce( ( ) => {
		initCollections();
	}, 200 ) );
}

function init() {
	initCollections();
	initEvents();
}

export default { init };
