import InfiniteScroll from 'infinite-scroll';
import Loader from './Loader';

const LoadMore = {};
const main = document.getElementById( 'main' );
const loadMoreContainer = main.querySelector( '.js-loadMore-container' );
const pagination = main.querySelector( '.pagination' );


function insertLoadMoreButton() {
	const button = document.createElement( 'button' );
	button.classList.add( 'btn', 'btn--loadMore', 'js-loadMore-btn' );
	button.textContent = 'Load more';
	pagination.parentNode.appendChild( button );
}

function initInfScroll() {
	return new InfiniteScroll( loadMoreContainer, {
		path: 'a.next.page-numbers',
		append: '.post',
		history: false,
		button: '.js-loadMore-btn',
		scrollThreshold: false,
		hideNav: '.pagination',
		status: '.infscroll-status',
	} );
}

LoadMore.init = function init() {
	if ( !pagination || !loadMoreContainer ) {
		return;
	}

	insertLoadMoreButton();
	Loader.insertInfiniteScrollStatus( pagination.parentNode );
	LoadMore.infScroll = initInfScroll();
};

export default LoadMore;
