import 'classlist.js';
import ScrollLock from 'body-scroll-lock';
import Breakpoint from '../utilities/Breakpoint';

const navbarToggle = document.getElementById( 'navbar-burger' );
const navMain = document.getElementById( 'navMain' );
const submenuToggle = navMain.querySelectorAll( '.navMain-parent' );
const navMainOverlay = document.createElement( 'div' );
let navIsOpen = false;

function isOpen() {
	return navIsOpen;
}

function addAriaAttributes( button, index ) {
	const anchor = button.querySelector( 'a:first-child' );
	const id = `navMain-submenu-${index}`;
	const target = anchor.nextElementSibling;
	anchor.setAttribute( 'aria-expanded', false );
	anchor.setAttribute( 'aria-controls', id );
	target.setAttribute( 'id', id );
	target.setAttribute( 'aria-hidden', true );
}

function toggleItem( button, callback ) {
	const target = document.getElementById( button.getAttribute( 'aria-controls' ) );
	const isHidden = button.getAttribute( 'aria-expanded' ) === 'false';
	button.setAttribute( 'aria-expanded', isHidden );
	target.setAttribute( 'aria-hidden', !isHidden );
	navIsOpen = isHidden;

	if ( typeof callback === 'function' ) {
		callback.call( target, isHidden );
	}
}

function closeOpenItem( button ) {
	const openItem = navMain.querySelector( '[aria-expanded="true"]' );
	if ( openItem && openItem !== button ) {
		toggleItem( openItem );
		navMain.classList.remove( 'is-open' );
	}
}

function toggleDesktopItem( button ) {
	closeOpenItem( button );
	toggleItem( button );
	navMain.classList.toggle( 'is-open' );
}

function disableBodyScroll( isHidden ) {
	if ( isHidden ) {
		ScrollLock.disableBodyScroll( this );
	} else {
		ScrollLock.enableBodyScroll( this );
	}
}

function handleBreakpointEvents( e ) {
	const button = this.querySelector( 'a:first-child' );

	if ( e.target === button ) {
		e.preventDefault();
	}

	if ( Breakpoint.get() === 'mobile' ) {
		toggleItem( button );
	} else {
		toggleDesktopItem( button );
	}
}

function setupSubmenus() {
	const submenuCount = submenuToggle.length;
	for ( let i = 0; i < submenuCount; i += 1 ) {
		addAriaAttributes( submenuToggle[i], i + 1 );
		submenuToggle[i].addEventListener( 'click', handleBreakpointEvents.bind( submenuToggle[i] ) );
	}
}

function setupOverlay() {
	navMainOverlay.classList.add( 'navMain-overlay' );
	navMain.appendChild( navMainOverlay );
}

function initEvents() {
	navbarToggle.addEventListener( 'click', () => toggleItem( navbarToggle, disableBodyScroll ) );
	navMainOverlay.addEventListener( 'click', closeOpenItem );
}

function init() {
	setupSubmenus();
	setupOverlay();
	initEvents();
}

export default {
	init,
	closeOpenItem,
	isOpen,
};
