import 'classlist.js';
import Flickity from 'flickity';

const main = document.getElementById( 'main' );
const carousels = main.querySelectorAll( '.js-carousel' );
const flkty = [];

function initFlickity( el ) {
	const hasDots = el.getAttribute( 'data-flickity-dots' ) !== 'false';

	return new Flickity( el, {
		pageDots: hasDots,
		prevNextButtons: true,
		groupCells: true,
		arrowShape: {
			x0: 10, x1: 60, y1: 50, x2: 65, y2: 45, x3: 20,
		},
	} );
}

function initCarousels() {
	for ( let i = 0; i < carousels.length; i += 1 ) {
		flkty.push( initFlickity( carousels[i] ) );
	}
}

function init() {
	initCarousels();
}

export default { init };
