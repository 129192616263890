import $ from 'jquery';
import 'magnific-popup';

const $main = $( '#main' );
const $galleries = $main.find( '.js-gallery' );

function initGalleries() {
	$galleries.each( ( i, gallery ) => {
		$( gallery ).find( 'a' ).magnificPopup( {
			type: 'image',
			image: {
				titleSrc: item => `${item.el.attr( 'data-title' )}<small>${item.el.attr( 'data-caption' )}</small> `,
			},
			gallery: {
				enabled: true,
			},
		} );
	} );
}

function init() {
	initGalleries();
}

export default { init };
