import '../lib/matches';

const collapsible = document.querySelectorAll( '.collapsible [aria-controls]' );

function removeHeight() {
	this.style.height = null;
	this.removeEventListener( 'transitionend', this.transitionendEvent );
}

function collapseItem( element ) {
	const item = element;
	const itemHeight = item.scrollHeight;
	const itemTransition = item.style.transition;
	item.style.transition = '';
	requestAnimationFrame( () => {
		item.style.height = `${itemHeight}px`;
		item.style.transition = itemTransition;
		requestAnimationFrame( () => {
			item.style.height = '0px';
			item.setAttribute( 'aria-hidden', true );
		} );
	} );
}

function expandItem( element ) {
	const item = element;
	const itemHeight = item.scrollHeight;
	item.style.height = `${itemHeight}px`;
	item.transitionendEvent = removeHeight.bind( item );
	item.addEventListener( 'transitionend', item.transitionendEvent );
	item.setAttribute( 'aria-hidden', false );
}

function toggleItem() {
	const target = document.getElementById( this.getAttribute( 'aria-controls' ) );
	const isCollapsed = this.getAttribute( 'aria-expanded' ) === 'false';
	this.setAttribute( 'aria-expanded', isCollapsed );

	if ( isCollapsed ) {
		expandItem( target );
	} else {
		collapseItem( target );
	}
}

function initEvents() {
	for ( let i = 0; i < collapsible.length; i += 1 ) {
		collapsible[i].addEventListener( 'click', toggleItem.bind( collapsible[i] ) );
	}
}

function init() {
	initEvents();
}

export default {
	init,
};
