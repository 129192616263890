import 'classlist.js';
import Breakpoint from '../utilities/Breakpoint';
import NavMain from './NavMain';

const navbar = document.getElementById( 'navbar' );
const boundry = navbar.offsetHeight;
const inertia = 2;
let scrollY = 0;
let prevScrollY = 0;
let ticking = false;

function handleScrollUp() {
	prevScrollY = scrollY;
	scrollY = window.scrollY + inertia;

	if ( scrollY > boundry ) {
		document.body.classList.toggle( 'scrollDown', scrollY > prevScrollY );
		if ( scrollY > prevScrollY && NavMain.isOpen() && Breakpoint.get() !== 'mobile' ) {
			NavMain.closeOpenItem( false );
		}
	} else if ( scrollY === 0 ) {
		document.body.classList.remove( 'scrollDown' );
	}

	ticking = false;
}

function requestTick() {
	if ( !ticking ) {
		window.requestAnimationFrame( handleScrollUp );
		ticking = true;
	}
}

function init() {
	window.addEventListener( 'scroll', requestTick );
}

export default {
	init,
};
