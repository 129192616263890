import debounce from 'lodash.debounce';
import Breakpoint from '../utilities/Breakpoint';

const pageHeroImage = document.getElementById( 'pageHero-image' );
let lastBreakpoint = 'desktop';

function fitSvgImage() {
	const image = pageHeroImage.querySelector( 'image' );
	const bp = Breakpoint.get();

	if ( bp !== lastBreakpoint ) {
		const x = ( bp === 'mobile' ) ? '0' : '34%';
		const y = ( bp === 'mobile' ) ? '15%' : '0';
		const width = ( bp === 'mobile' ) ? '100%' : '66%';
		image.setAttribute( 'x', x );
		image.setAttribute( 'y', y );
		image.setAttribute( 'width', width );
		lastBreakpoint = bp;
	}
}

function init() {
	if ( !pageHeroImage ) {
		return;
	}

	fitSvgImage();

	window.addEventListener( 'resize', debounce( ( ) => {
		fitSvgImage();
	}, 200 ) );
}

export default {
	init,
};
