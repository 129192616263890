import 'focus-visible';
import svg4everybody from 'svg4everybody';
import BlockLink from './components/BlockLink';
import Cards from './components/Cards';
import Carousel from './components/Carousel';
import Collapsible from './components/Collapsible';
import Gallery from './components/Gallery';
import LoadMore from './components/LoadMore';
import Navbar from './components/Navbar';
import NavMain from './components/NavMain';
import PageHero from './components/PageHero';

BlockLink.init();
Cards.init();
Carousel.init();
Collapsible.init();
Gallery.init();
LoadMore.init();
Navbar.init();
NavMain.init();
PageHero.init();
svg4everybody();
